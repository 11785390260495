import { IMonsterDetails } from "./Types";

export const defaultMonsterDetails = {
  alignment: "",
  armor_class: [{ value: 0, type: "" }],
  charisma: 0,
  constitution: 0,
  dexterity: 0,
  hit_points: 0,
  hit_dice: "",
  index: "",
  intelligence: 0,
  name: "",
  size: "",
  strength: 0,
  subtype: "",
  type: "",
  url: "",
  wisdom: 0,
  challenge_rating: 0,
  speed: { walk: "0ft", swim: "0ft" },
  actions: [{ name: "", desc: "" }],
  xp: 0,
  special_abilities: [{ name: "", desc: "" }],
  condition_immunities: [""],
  damage_immunities: [""],
  damage_resistances: [""],
  damage_vulnerabilities: [""],
  languages: [""],
} as IMonsterDetails;
